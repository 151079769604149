/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Dropdown from "@/components/ui/dropdown";
import DatePicker from "@/components/ui/datepicker";
import { Input } from "@/components/ui/input";
import Header from "@/components/ui/header";
import { Button } from "@/components/ui/button";
import NationalIDInput from "@/components/nationalIDInput";
import { district, provinces, pv_name } from "@/data/thai_address";
import Combobox from "@/components/combobox";
import { idcheck } from "@/lib/api/login";
import { useQueries } from "@tanstack/react-query";
import { getSubdistrict } from "@/lib/api/getAddress";
import { createUser } from "@/lib/api/createUser";
import Swal from "sweetalert2";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "@/hooks/AuthContext";
import { getSchool } from "@/lib/api/getSchool";
import Autocomplete from "react-widgets/Combobox";
import SumModal from "@/components/sumModal";
import { getRangeRegister } from "@/lib/api/getTimeSwitch";

interface UserData {
  data?: {
    id?: number;
    student_identityid_type?: null;
    student_identityid?: string;
    student_prefix?: string;
    student_firstname?: string;
    student_surname?: string;
    student_birthday?: string;
    student_level?: string;
    student_school?: string;
    student_school_subdistrict?: number;
    student_house_no?: string;
    student_village_no?: string;
    student_addr_alley?: string;
    student_addr_lane?: string;
    student_addr_street?: string;
    student_addr_subdistrict?: number;
    student_mobile?: string;
    parent1_prefix?: string;
    parent1_firstname?: string;
    parent1_surname?: string;
    parent1_relationship?: string;
    parent1_mobile?: string;
    parent2_prefix?: string;
    parent2_firstname?: string;
    parent2_surname?: string;
    parent2_relationship?: string;
    parent2_mobile?: string;
    teacher_prefix?: string;
    teacher_firstname?: string;
    teacher_surname?: string;
    teacher_mobile?: string;
  };
  success?: boolean;
}

interface Auth {
  user: UserData | object | null;
  signin: (idcard: string, date: string | undefined) => Promise<void>;
  signout: () => void;
  loading: boolean;
}

interface Student {
  prefix: string;
  firstName: string;
  lastName: string;
  nationalID: string;
  DOB: Date | undefined;
  phone: string;
  level: string;
  // address: string,
  house_no: string | undefined;
  village_no: string | undefined;
  addr_alley: string | undefined;
  addr_lane: string | undefined;
  addr_street: string | undefined;
  province: string;
  district: string;
  subDistrict: string;
  zipCode: string;
  schoolID: string;
  schoolProvince: string | undefined;
  schoolDistrict: string | undefined;
  schoolSubDistrict: string;
}

interface Parent {
  prefix: string;
  firstName: string;
  lastName: string;
  relation: string;
  phone: string;
}

interface Teacher {
  prefix: string;
  firstName: string;
  lastName: string;
  phone: string;
}

interface Status {
  isNationalID: boolean;
  isVisible: boolean;
  isAcceptCondition: boolean;
}

interface Sds {
  zip_code: number;
  name_th: string;
  name_en: string;
  id: number;
}

interface Scds {
  zip_code: number;
  name_th: string;
  name_en: string;
  id: number;
}

const Register = () => {
  const navigate = useNavigate();
  const auth = useContext<Auth | null>(AuthContext);
  const [dataStudent, setDataStudent] = useState<Student>({
    prefix: "",
    firstName: "",
    lastName: "",
    nationalID: "", // get from previous page
    DOB: undefined,
    phone: "",
    level: "",
    // address: '', // not done
    house_no: "",
    village_no: "",
    addr_alley: "",
    addr_lane: "",
    addr_street: "",
    province: "",
    district: "",
    subDistrict: "",
    zipCode: "",
    schoolID: "โรงเรียน",
    schoolProvince: "", // somehow
    schoolDistrict: "", // somehow
    schoolSubDistrict: "",
  });

  const [dataParents, setDataParents] = useState<Parent[]>([
    {
      prefix: "",
      firstName: "",
      lastName: "",
      relation: "",
      phone: "",
    },
    {
      prefix: "",
      firstName: "",
      lastName: "",
      relation: "",
      phone: "",
    },
  ]);

  const [dataTeacher, setDataTeacher] = useState<Teacher>({
    prefix: "",
    firstName: "",
    lastName: "",
    phone: "",
  });

  const [status, setStatus] = useState<Status>({
    isNationalID: false,
    isVisible: true,
    isAcceptCondition: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const prefixs = [
    { key: "เด็กชาย", value: "เด็กชาย" },
    { key: "เด็กหญิง", value: "เด็กหญิง" },
  ];
  const prefixsAdult = [
    { key: "นาย", value: "นาย" },
    { key: "นาง", value: "นาง" },
    { key: "นางสาว", value: "นางสาว" },
  ];

  const levels = [
    { key: "level5", value: "ประถมศึกษาปีที่ 5" },
    { key: "level6", value: "ประถมศึกษาปีที่ 6" },
  ];

  const relationship = ["บิดา", "มารดา", "ญาติ"];

  const [{ data: schoolsData }, { data: canRegister }] = useQueries({
    queries: [
      {
        queryKey: ["schools", dataStudent.schoolID],
        queryFn: () => getSchool(dataStudent.schoolID),
      },
      {
        queryKey: ["register"],
        queryFn: () => getRangeRegister(),
      },
    ],
  });

  const [, setSds] = useState<Sds[]>([]);
  const [scds, setScds] = useState<Scds[]>([]);

  const subDistrict = (
    pr: string | undefined,
    dt: string | undefined,
    setFn: React.Dispatch<React.SetStateAction<Sds[]>>
  ) =>
    getSubdistrict(pv_name(pr)).then((res) => {
      setFn(
        res.data?.data?.[0].districts.find((dt_: { name_th: string }) => {
          return dt_.name_th == dt;
        })?.subdistricts
      );
    });

  useEffect(() => {
    subDistrict(dataStudent.province, dataStudent.district, setSds);
  }, [dataStudent.district]);

  useEffect(() => {
    subDistrict(
      dataStudent.schoolProvince,
      dataStudent.schoolDistrict,
      setScds
    );
  }, [dataStudent.schoolDistrict]);

  const handleCheckId = async () => {
    const date: string | undefined = dataStudent.DOB?.toLocaleDateString(
      "th-TH",
      { day: "2-digit", month: "2-digit", year: "2-digit" }
    );

    await idcheck(dataStudent.nationalID).then((res) => {
      if (res.data?.message == "IDcard does not taken") {
        if (canRegister?.["data"]?.["config"] == false) {
          Swal.fire("Error", "ยังไม่ถึงวันเปิดรับสมัคร", "error").then(() => {
            navigate("/");
          });
        } else {
          setStatus({ ...status, ["isVisible"]: false });
        }
      } else if (res.data?.message == "IDcard has taken") {
        auth
          ?.signin(dataStudent.nationalID, date)
          .then(() => {
            navigate("/Profile");
          })
          .catch((err) => {
            err.response.data.message == "Undefined login credentials"
              ? Swal.fire(
                  "Error",
                  "กรุณาตรวจสอบวัน เดือน ปี เกิดให้ถูกต้อง",
                  "error"
                )
              : Swal.fire("Error", "เกิดข้อผิดพลาด", "error");
          });
      }
    }).catch(() => {
      Swal.fire("Error", "เกิดข้อผิดพลาด", "error");
    }
    );
  };

  const handleSubmit = (
    e: /* eslint-disable react-hooks/exhaustive-deps */
    React.FormEvent // MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const afterSubmit = () => {
    setIsModalOpen(false);
    if (dataStudent.schoolSubDistrict.length == 0) {
      Swal.fire("Error", "กรุณากรอกที่อยู่สถานศึกษาให้ครบถ้วน ", "error");
    } else {
      createUser({
        student_identityid_type: null,
        student_identityid: dataStudent.nationalID,
        student_prefix: dataStudent.prefix,
        student_firstname: dataStudent.firstName,
        student_surname: dataStudent.lastName,
        student_birthday: dataStudent.DOB?.toLocaleDateString("th-TH", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        }),
        student_level: dataStudent.level,
        student_school: dataStudent.schoolID,
        student_school_subdistrict: dataStudent.schoolSubDistrict,
        student_house_no: dataStudent.house_no,
        student_village_no: dataStudent.village_no,
        student_addr_alley: dataStudent.addr_alley,
        student_addr_lane: dataStudent.addr_lane,
        student_addr_street: dataStudent.addr_street,
        student_addr_subdistrict: dataStudent.subDistrict || '100101',
        student_mobile: dataStudent.phone,
        parent1_prefix: dataParents[0].prefix,
        parent1_firstname: dataParents[0].firstName,
        parent1_surname: dataParents[0].lastName,
        parent1_relationship: dataParents[0].relation,
        parent1_mobile: dataParents[0].phone,
        parent2_prefix: dataParents[1].prefix,
        parent2_firstname: dataParents[1].firstName,
        parent2_surname: dataParents[1].lastName,
        parent2_relationship: dataParents[1].relation,
        parent2_mobile: dataParents[1].phone,
        teacher_prefix: dataTeacher.prefix,
        teacher_firstname: dataTeacher.firstName,
        teacher_surname: dataTeacher.lastName,
        teacher_mobile: dataTeacher.phone,
      })
        .then((res) => {
          if (res.data.success == true) {
            Swal.fire("Successful", "รับสมัครเสร็จสมบูรณ์", "success").then(
              () => {
                auth
                  ?.signin(
                    dataStudent.nationalID,
                    dataStudent.DOB?.toLocaleDateString("th-TH", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    })
                  )
                  .then(() => {
                    navigate("/profile");
                  });
              }
            );
          } else if (res.data.message == "Duplicate IDcard in DB") {
            Swal.fire(
              "Error",
              "เลขบัตรประจำตัวประชาชนนี้ได้ถูกใช้ไปแล้ว",
              "error"
            );
          }
          // if (res.data)
        })
        .catch((err) => {
          // eslint-disable-next-line no-cond-assign, no-constant-condition
          if ((err.response.data.message = "Empty data")) {
            Swal.fire("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
          }
        });
    }
  };

  useEffect(() => {
    if (auth && auth.user) {
      navigate("/profile");
    }
  }, [auth]);

  // useEffect(() => {
  //   setDataStudent({
  //     ...dataStudent,
  //     ["schoolProvince"]: schools.findLast((x) => x.key == dataStudent.schoolID)
  //       ?.schoolProvince,
  //     ["schoolDistrict"]: schools.findLast((x) => x.key == dataStudent.schoolID)
  //       ?.schoolDistrict,
  //   });
  // }, [dataStudent.schoolID]);

  // useEffect(() => {
  //   console.log(dataStudent);
  //   console.log(dataParents);
  //   console.log(dataTeacher);
  // }, [dataStudent, dataParents, dataTeacher]);

  return (
    <div>
      {status.isVisible ? (
        <div className="flex flex-col gap-2 w-full">
          <Header text="การสมัครสอบ" />
          <div className="grid md:grid-cols-2 gap-2 w-full">
            <div>
              <label className="font-bold" htmlFor="citizenid">
                เลขบัตรประจำตัวประชาชน <span className="text-secondary">*</span>
              </label>
              <NationalIDInput
                value={dataStudent.nationalID}
                onChange={(id, valid) => {
                  setDataStudent({ ...dataStudent, ["nationalID"]: id });
                  setStatus({ ...status, ["isNationalID"]: valid });
                }}
              />
            </div>
            <div>
              <label htmlFor="studentDOB" className="font-bold mb-2">
                วัน เดือน ปีเกิด <span className="text-secondary">*</span>
              </label>
              <DatePicker
                variant={"outline"}
                isDisable={false}
                id={"studentDOB"}
                isRequired={true}
                placeHolder="กรอกวัน เดือน ปีเกิด"
                date={dataStudent.DOB}
                fromYear={(new Date().getFullYear() - 20)}
                toYear={(new Date().getFullYear() - 4)}
                onChange={(value: Date | undefined) =>
                  setDataStudent({ ...dataStudent, ["DOB"]: value })
                }
              />
            </div>
          </div>

          <div className="grid gap-2 md:grid-cols-2">
            <Button
              aria-label="ปุ่มยืนยัน"
              className="w-full"
              onClick={handleCheckId}
              disabled={
                !status.isNationalID ||
                dataStudent.nationalID == "" ||
                dataStudent.DOB == undefined
              }
            >
              ยืนยัน
            </Button>
            <Button aria-label="ปุ่มยกเลิก" className="w-full" variant={"gray"}>
              ยกเลิก
            </Button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="flex flex-col gap-y-8"
        >
          <div className="flex flex-col gap-y-2">
            <Header text={"ข้อมูลนักเรียน"} />
            <div className="flex flex-col gap-y-2">
              <div>
                <label htmlFor="studentFirstName" className="font-bold mb-2">
                  ชื่อจริง <span className="text-secondary">*</span>
                </label>
                <div className="grid grid-cols-4 gap-x-2">
                  <Dropdown
                    name="studentPrefix"
                    id="studentPrefix"
                    isRequire={true}
                    placeHolder="คำนำหน้า"
                    onChange={(value: string) =>
                      setDataStudent({ ...dataStudent, ["prefix"]: value })
                    }
                    obj={prefixs}
                    style="col-span-1"
                  />
                  <Input
                    name="studentFirstName"
                    id="studentFirstName"
                    required={true}
                    className="border-2 border-black col-span-3"
                    onChange={(e) =>
                      setDataStudent({
                        ...dataStudent,
                        ["firstName"]: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="กรอกชื่อจริง"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="studentLastName" className="font-bold mb-2">
                  นามสกุล <span className="text-secondary">*</span>
                </label>
                <Input
                  name="studentLastName"
                  id="studentLastName"
                  required={true}
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataStudent({
                      ...dataStudent,
                      ["lastName"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="กรอกนามสกุล"
                />
              </div>
              <div>
                <label htmlFor="studentNationalID" className="font-bold mb-2">
                  เลขบัตรประชาชน <span className="text-secondary">*</span>
                </label>
                <Input
                  name="studentNationalID"
                  id="studentNationalID"
                  className="border-2 border-black bg-gray cursor-not-allowed"
                  readOnly={true}
                  value={dataStudent.nationalID}
                  type="text"
                />
              </div>
              <div>
                <label htmlFor="studentDOB" className="font-bold mb-2">
                  วัน เดือน ปีเกิด <span className="text-secondary">*</span>
                </label>
                <DatePicker
                  variant={"datePicker"}
                  isDisable={true}
                  id={"studentDOB"}
                  isRequired={true}
                  placeHolder="กรอกวัน เดือน ปีเกิด"
                  date={dataStudent.DOB}
                  fromYear={2011}
                  toYear={2015}
                  onChange={(value: Date | undefined) =>
                    setDataStudent({ ...dataStudent, ["DOB"]: value })
                  }
                />
              </div>
              <div>
                <label htmlFor="studentPhone" className="font-bold mb-2">
                  หมายเลขโทรศัพท์ของนักเรียน{" "}
                  <span className="text-secondary">{`(ถ้ามี)`}</span>
                </label>
                <Input
                  id="studentPhone"
                  onChange={(e) =>
                    setDataStudent({
                      ...dataStudent,
                      ["phone"]: e.target.value,
                    })
                  }
                  className="border-2 border-black"
                  placeholder="กรอกหมายเลขโทรศัพท์"
                  maxLength={10}
                  minLength={10}
                  pattern="^\d{10}$"
                  required={false}
                />
              </div>
              <div>
                <label htmlFor="studentLevel" className="font-bold mb-2">
                  ระดับชั้นการศึกษา <span className="text-secondary">*</span>
                </label>
                <Dropdown
                  name="studentLevel"
                  id="studentLevel"
                  isRequire={true}
                  placeHolder="เลือกระดับชั้นการศึกษา"
                  onChange={(value: string) =>
                    setDataStudent({ ...dataStudent, ["level"]: value })
                  }
                  obj={levels}
                  style=""
                />
              </div>
              {/* <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  ปัจจุบันอาศัยอยู่ บ้านเลขที่{" "}
                  <span className="text-secondary">*</span>
                </label>
                <Input
                  name="student_house_no"
                  id="student_house_no"
                  required={true}
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataStudent({
                      ...dataStudent,
                      ["house_no"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="บ้านเลขที่"
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  {"หมู่ที่ "}
                </label>
                <Input
                  name="student_village_no"
                  id="student_village_no"
                  required={false}
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataStudent({
                      ...dataStudent,
                      ["village_no"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="หมู่ที่"
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  {"ถนน "}
                </label>
                <Input
                  name="student_addr_street"
                  id="student_addr_street"
                  required={false}
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataStudent({
                      ...dataStudent,
                      ["addr_street"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="ถนน"
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  {"จังหวัด "}
                  <span className="text-secondary">*</span>
                </label>
                <Combobox
                  datas={provinces}
                  place="จังหวัด"
                  input={dataStudent.province}
                  setInput={(new_val) =>
                    setDataStudent({
                      ...dataStudent,
                      ["province"]: new_val,
                    })
                  }
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  {"อำเภอ/เขต "}
                  <span className="text-secondary">*</span>
                </label>
                <Combobox
                  datas={district(dataStudent.province)}
                  place="อำเภอ/เขต"
                  input={dataStudent.district}
                  setInput={(new_val) =>
                    setDataStudent({
                      ...dataStudent,
                      ["district"]: new_val,
                    })
                  }
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  {"ตำบล/แขวง "}
                  <span className="text-secondary">*</span>
                </label>
                <Combobox
                  datas={sds?.map((sd) => {
                    return { value: String(sd.id), label: sd.name_th };
                  })}
                  place="ตำบล/แขวง"
                  input={dataStudent.province}
                  setInput={(new_val) =>
                    setDataStudent({
                      ...dataStudent,
                      ["subDistrict"]: new_val,
                    })
                  }
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  {"รหัสไปรษณีย์ "}
                  <span className="text-transparent">*</span>
                </label>
                <Input
                  className="border-2 border-black bg-gray cursor-not-allowed"
                  name="student_addr_zipcode"
                  id="student_addr_zipcode"
                  required={false}
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  readOnly={true}
                  value={
                    sds?.find((sd) => {
                      return sd.id == parseInt(dataStudent.subDistrict);
                    })?.zip_code
                  }
                />
              </div> */}
            </div>
            <div className="flex flex-col gap-y-2 mt-8">
              <Header text={"ข้อมูลโรงเรียน"} />

              <div className="md:col-span-2">
                <label htmlFor="studentSchoolID" className="font-bold mb-2">
                  ปัจจุบันศึกษาอยู่โรงเรียน{" "}
                  <span className="text-secondary">*</span>
                </label>
                {/* <Combobox
                  datas={schoolsData?.data?.data.map((school: { school_name: string; }) => {
                    return { value: String(school.school_name), label: school.school_name };
                  }
                  )}
                  place="เลือกชื่อโรงเรียน"
                  input={dataStudent.schoolID}
                  setInput={(new_val) => {
                    return setDataStudent({
                      ...dataStudent,
                      ["schoolID"]: new_val,
                    })
                  }
                  }
                /> */}
                <Autocomplete
                  data={schoolsData?.data?.data.map(
                    (school: { school_name: string }) => {
                      return String(school.school_name);
                    }
                  )}
                  onChange={(e: string) => {
                    setDataStudent({
                      ...dataStudent,
                      ["schoolID"]: e,
                    });
                  }}
                  inputProps={{
                    required: true,
                  }}
                  filter="contains"
                  placeholder="เลือกชื่อโรงเรียน"
                  containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                />
              </div>
              <div>
                <label
                  htmlFor="studentSchoolProvince"
                  className="font-bold mb-2"
                >
                  จังหวัด <span className="text-secondary">*</span>
                </label>
                <Combobox
                  datas={provinces}
                  place="จังหวัด"
                  input={dataStudent.schoolProvince}
                  setInput={(new_val) =>
                    setDataStudent({
                      ...dataStudent,
                      ["schoolProvince"]: new_val,
                    })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="studentSchoolDistrict"
                  className="font-bold mb-2"
                >
                  อำเภอ <span className="text-secondary">*</span>
                </label>
                <Combobox
                  datas={district(dataStudent.schoolProvince)}
                  place="อำเภอ/เขต"
                  input={dataStudent.schoolDistrict}
                  setInput={(new_val) =>
                    setDataStudent({
                      ...dataStudent,
                      ["schoolDistrict"]: new_val,
                    })
                  }
                />
              </div>
              <div className="md:col-span-1">
                <label htmlFor="studentAddress" className="font-bold mb-2">
                  ตำบล/แขวง <span className="text-secondary">*</span>
                </label>
                <Combobox
                  datas={scds?.map((scd) => {
                    return { value: String(scd.id), label: scd.name_th };
                  })}
                  place="ตำบล/แขวง"
                  input={dataStudent.schoolProvince}
                  setInput={(new_val) =>
                    setDataStudent({
                      ...dataStudent,
                      ["schoolSubDistrict"]: new_val,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <Header text={"ข้อมูลผู้ปกครอง"} />
            <div className="font-bold my-4">ผู้ปกครองคนที่ 1</div>
            <div className="flex flex-col gap-y-2">
              <div>
                <label htmlFor="parent1FirstName" className="font-bold mb-2">
                  ชื่อจริง <span className="text-secondary">*</span>
                </label>
                <div className="grid grid-cols-4 gap-x-2">
                  <Dropdown
                    name="parent1Prefix"
                    id="parent1Prefix"
                    isRequire={true}
                    placeHolder="คำนำหน้า"
                    onChange={(value: string) =>
                      setDataParents([
                        { ...dataParents[0], ["prefix"]: value },
                        dataParents[1],
                      ])
                    }
                    obj={prefixsAdult}
                    style="col-span-1"
                  />
                  <Input
                    name="parent1FirstName"
                    id="parent1FirstName"
                    className="border-2 border-black col-span-3"
                    onChange={(e) =>
                      setDataParents([
                        { ...dataParents[0], ["firstName"]: e.target.value },
                        dataParents[1],
                      ])
                    }
                    required={true}
                    type="text"
                    placeholder="กรอกชื่อจริง"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="parent1LastName" className="font-bold mb-2">
                  นามสกุล <span className="text-secondary">*</span>
                </label>
                <Input
                  name="parent1LastName"
                  id="parent1LastName"
                  required={true}
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataParents([
                      { ...dataParents[0], ["lastName"]: e.target.value },
                      dataParents[1],
                    ])
                  }
                  type="text"
                  placeholder="กรอกนามสกุล"
                />
              </div>
              <div>
                <label htmlFor="parent1Relation" className="font-bold mb-2">
                  ความสัมพันธ์ <span className="text-secondary">*</span>
                </label>
                <Autocomplete
                  data={relationship}
                  onChange={(e) => {
                    setDataParents([
                      { ...dataParents[0], ["relation"]: e },
                      dataParents[1],
                    ]);
                  }}
                  inputProps={{
                    required: true,
                  }}
                  filter="contains"
                  placeholder="กรอกความสัมพันธ์"
                  containerClassName="border-2 border-black rounded-md text-sm font-normal !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                />
              </div>
              <div>
                <label htmlFor="parent1Phone" className="font-bold mb-2">
                  หมายเลขโทรศัพท์ <span className="text-secondary">*</span>
                </label>
                <Input
                  name="parent1Phone"
                  id="parent1Phone"
                  required={true}
                  onChange={(e) =>
                    setDataParents([
                      { ...dataParents[0], ["phone"]: e.target.value },
                      dataParents[1],
                    ])
                  }
                  className="border-2 border-black"
                  placeholder="กรอกหมายเลขโทรศัพท์"
                  maxLength={10}
                  minLength={10}
                  pattern="^\d{10}$"
                />
              </div>
            </div>
            <div className="font-bold my-4">ผู้ปกครองคนที่ 2</div>
            <div className="flex flex-col gap-y-2">
              <div>
                <label htmlFor="parent2FirstName" className="font-bold mb-2">
                  ชื่อจริง
                </label>
                <div className="grid grid-cols-4 gap-x-2">
                  <Dropdown
                    name="parent2Prefix"
                    id="parent2Prefix"
                    isRequire={false}
                    placeHolder="คำนำหน้า"
                    onChange={(value: string) =>
                      setDataParents([
                        dataParents[0],
                        { ...dataParents[1], ["prefix"]: value },
                      ])
                    }
                    obj={prefixsAdult}
                    style="col-span-1"
                  />
                  <Input
                    name="parent2FirstName"
                    id="parent2FirstName"
                    required={false}
                    className="border-2 border-black col-span-3"
                    onChange={(e) =>
                      setDataParents([
                        dataParents[0],
                        { ...dataParents[1], ["firstName"]: e.target.value },
                      ])
                    }
                    type="text"
                    placeholder="กรอกชื่อจริง"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="parent2LastName" className="font-bold mb-2">
                  นามสกุล
                </label>
                <Input
                  required={false}
                  name="parent2LastName"
                  id="parent2LastName"
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataParents([
                      dataParents[0],
                      { ...dataParents[1], ["lastName"]: e.target.value },
                    ])
                  }
                  type="text"
                  placeholder="กรอกนามสกุล"
                />
              </div>
              <div>
                <label htmlFor="parent2Relation" className="font-bold mb-2">
                  ความสัมพันธ์
                </label>
                <Autocomplete
                  data={relationship}
                  onChange={(e) => {
                    setDataParents([
                      dataParents[0],
                      { ...dataParents[1], ["relation"]: e },
                    ]);
                  }}
                  filter="contains"
                  placeholder="กรอกความสัมพันธ์"
                  containerClassName="border-2 border-black rounded-md text-sm font-normal !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                />
              </div>
              <div>
                <label htmlFor="parent2Phone" className="font-bold mb-2">
                  หมายเลขโทรศัพท์
                </label>
                <Input
                  required={false}
                  name="parent2Phone"
                  id="parent2Phone"
                  onChange={(e) =>
                    setDataParents([
                      dataParents[0],
                      { ...dataParents[1], ["phone"]: e.target.value },
                    ])
                  }
                  className="border-2 border-black"
                  placeholder="กรอกหมายเลขโทรศัพท์"
                  maxLength={10}
                  minLength={10}
                  pattern="^\d{10}$"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <Header text={"ข้อมูลครูที่ปรึกษา"} />
            <div className="flex flex-col gap-y-2">
              <div>
                <label htmlFor="teacherFirstName" className="font-bold mb-2">
                  ชื่อจริง <span className="text-secondary">*</span>
                </label>
                <div className="grid grid-cols-4 gap-x-2">
                  <Dropdown
                    name="teacherPrefix"
                    id="teacherPrefix"
                    isRequire={true}
                    placeHolder="คำนำหน้า"
                    onChange={(value: string) =>
                      setDataTeacher({ ...dataTeacher, ["prefix"]: value })
                    }
                    obj={prefixsAdult}
                    style="col-span-1"
                  />
                  <Input
                    required={true}
                    name="teacherFirstName"
                    id="teacherFirstName"
                    className="border-2 border-black col-span-3"
                    onChange={(e) =>
                      setDataTeacher({
                        ...dataTeacher,
                        ["firstName"]: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="กรอกชื่อจริง"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="teacherLastName" className="font-bold mb-2">
                  นามสกุล <span className="text-secondary">*</span>
                </label>
                <Input
                  required={true}
                  name="teacherLastName"
                  id="teacherLastName"
                  className="border-2 border-black"
                  onChange={(e) =>
                    setDataTeacher({
                      ...dataTeacher,
                      ["lastName"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="กรอกนามสกุล"
                />
              </div>
              <div>
                <label htmlFor="teacherPhone" className="font-bold mb-2">
                  หมายเลขโทรศัพท์ <span className="text-secondary">*</span>
                </label>
                <Input
                  required={true}
                  name="teacherPhone"
                  id="teacherPhone"
                  onChange={(e) =>
                    setDataTeacher({
                      ...dataTeacher,
                      ["phone"]: e.target.value,
                    })
                  }
                  className="border-2 border-black"
                  placeholder="กรอกหมายเลขโทรศัพท์"
                  maxLength={10}
                  minLength={10}
                  pattern="^\d{10}$"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <input
              id="link-checkbox"
              type="checkbox"
              onChange={(e) =>
                setStatus({
                  ...status,
                  ["isAcceptCondition"]: e.target.checked,
                })
              }
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
            />
            <label
              htmlFor="link-checkbox"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ข้าพเจ้าขอยืนยันว่าข้อมูลทั้งหมดเหล่านี้เป็นจริง
              และอนุญาตให้นำข้อมูลไปใช้ต่อได้
            </label>
          </div>
          <div className="grid md:grid-cols-2 gap-x-8 gap-y-4">
            {/* <Link className="w-full" to="#"> */}
            <Button
              disabled={!status.isAcceptCondition}
              title="ปุ่มถัดไป"
              aria-label="ปุ่มถัดไป"
              className="w-full"
              type="submit"
              // type="button"
            >
              ถัดไป
            </Button>
            <SumModal
              dataStudent={dataStudent}
              dataParents={dataParents}
              dataTeacher={dataTeacher}
              open={isModalOpen}
              onOpenChange={setIsModalOpen}
              onClick={afterSubmit}
            />
            {/* </Link> */}
            <Button
              onClick={(e) => {
                e.preventDefault();
                setStatus({ ...status, ["isVisible"]: true });
              }}
              title="ปุ่มย้อนกลับ"
              aria-label="ปุ่มย้อนกลับ"
              className="w-full"
              variant={"gray"}
            >
              ย้อนกลับ
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Register;
