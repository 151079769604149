// import Header from "@/components/ui/header";
import { Button } from "@/components/ui/button";
import { Eye, EyeOff, FileBadge, FileText, Loader } from "lucide-react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQueries } from "@tanstack/react-query";
import { getExamResult, getExamResultPdf } from "@/lib/api/getExamResult";
import { getRangeShowScoreResult } from "@/lib/api/getTimeSwitch";
import { getScoreAnnounceNote } from "@/lib/api/getScoreAnnounceNote";
import { ProtectedRoute } from "@/components/ProtectedRoute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getCertificate, isCertificateAvailable } from "@/lib/api/getCertificate";

export default function Announcement() {
  const [eye, setEye] = useState(false);
  const [examResult, scoreAnnounceRange, footerNote] = useQueries({
    queries: [
      {
        queryKey: ["examResult"],
        queryFn: getExamResult,
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnMount: "always",
      },
      {
        queryKey: ["scoreAnnounceRange"],
        queryFn: getRangeShowScoreResult,
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnMount: "always",
      },
      {
        queryKey: ["footerNote"],
        queryFn: getScoreAnnounceNote,
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnMount: "always",
      }
    ],
  });

  const [examResultPdf, isCertAvailable] = useQueries({
    queries: [
      {
        queryKey: ["examResultPdf", examResult?.data?.data.data.id],
        queryFn: () => getExamResultPdf(examResult?.data?.data.data.id),
        retry: 1,
        enabled: examResult.status == "success" && scoreAnnounceRange?.data?.["data"]?.["config"],
        refetchOnMount: "always",
      },
      {
        queryKey: ["isCertificateAvailable", examResult?.data?.data.data.id],
        queryFn: () => isCertificateAvailable(examResult?.data?.data.data.id),
        retry: 1,
        enabled: examResult.status == "success" && scoreAnnounceRange?.data?.["data"]?.["config"],
        refetchOnMount: "always",
      }
    ],
  });

  const [certificate] = useQueries({
    queries: [
      {
        queryKey: ["certificate", examResult?.data?.data.data.id],
        queryFn: () => getCertificate(examResult?.data?.data.data.id),
        retry: 2,
        enabled: examResult.status == "success" && isCertAvailable?.data?.["data"]?.["certificate"],
        refetchOnMount: "always",
      },
    ],
  });

  const scores = [
    {
      subject: "ว01 คณิตศาสตร์",
      max: 60,
      score: examResult?.data?.["data"]?.["data"]?.["score"][0]?.["math_score"],
    },
    {
      subject: "ว02 ภาษาอังกฤษ",
      max: 60,
      score: examResult?.data?.["data"]?.["data"]?.["score"][0]?.["eng_score"],
    },
    {
      subject: "ว03 วิทยาศาสตร์",
      max: 60,
      score: examResult?.data?.["data"]?.["data"]?.["score"][0]?.["sci_score"],
    },
  ];

  const accountRanking =
    examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
      "student_level"
    ] == "level6"
      ? [
          // {
          //   id: "คะแนนรวม",
          //   rank: examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
          //     "student_rank"
          //   ],
          // },
          {
            id: "บัญชีหมายเลข 1",
            rank:
              examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                "rank2_show"
              ] == 1
                ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "rank2"
                  ] <= 30 &&
                  examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "rank2"
                  ] >= 1
                  ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                      "rank2"
                    ]
                  : "-"
                : "-",
          },
          {
            id: "บัญชีหมายเลข 2",
            rank:
              examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                "rank2_show"
              ] == 1
                ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "rank2"
                  ] <= 66 &&
                  examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "rank2"
                  ] >= 31
                  ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                      "rank2"
                    ] - 30
                  : "-"
                : "-",
          },
          {
            id: "บัญชีหมายเลข 3",
            rank:
              examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                "rank3_show"
              ] == 1
                ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.["rank3"]
                : "-",
          },
          {
            id: "บัญชีหมายเลข 4",
            rank:
              examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                "rank2_show"
              ] == 1
                ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "rank2"
                  ] <= 106 &&
                  examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "rank2"
                  ] >= 67
                  ? examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                      "rank2"
                    ] - 66
                  : "-"
                : "-",
          },
        ]
      : [
          {
            id: "คะแนนรวม",
            rank: examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
              "student_rank"
            ],
          },
        ];
  const handleOnClick = (type: string) => {
    let pdf;
    if (type === "score") {
      pdf = examResultPdf?.["data"]?.["data"];
    } else if (type === "certificate") {
      if (certificate.status === "error") {
        Swal.fire({
          title: "ไม่พบเกียรติบัตร",
          text: "ไม่พบเกียรติบัตรที่สามารถดาวน์โหลดได้",
          icon: "warning",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.href = "/announcement";
          }
        });
      } else {
        pdf = certificate?.["data"]?.["data"];
      }
    }
    const url = window.URL.createObjectURL(
      new Blob([pdf], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    if (type === "score") {
      link.download = `ผลสอบ_${examResult?.data?.data.data.exam_id}.pdf`;
    } else if (type === "certificate") {
      link.download = `เกียรติบัตร_${examResult?.data?.data.data.exam_id}.pdf`;
    }
    link.href = url;
    document.body.appendChild(link);
    if (type === "score" && examResultPdf.status === "success") {
      link.click();
    }
    else if (type === "certificate" && certificate.status === "success" && isCertAvailable?.data?.data.certificate == true) {
      link.click();
    }
    link.parentNode?.removeChild(link);
  };

  useEffect(() => {
    if (
      scoreAnnounceRange.status == "success" &&
      !scoreAnnounceRange?.data?.["data"]?.["config"]
    ) {
      Swal.fire({
        title: "ขณะนี้ยังไม่สามารถเข้าถึงหน้านี้ได้",
        text: "กรุณาตรวจสอบใหม่อีกครั้งในภายหลัง",
        icon: "warning",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreAnnounceRange?.data?.["data"]?.["config"]]);

  if (
    scoreAnnounceRange.status == "success" &&
    !scoreAnnounceRange?.data?.["data"]?.["config"]
  ) {
    return (
      <ProtectedRoute>
        <div> ยังไม่ประกาศผลสอบ </div>
      </ProtectedRoute>
    );
  }

  return (
    <ProtectedRoute>
      <div className="flex flex-col gap-y-4 text-sm">
        <div className="text-center text-sm font-bold">
          ผลการทดสอบวัดความรู้ความสามารถทางวิชาการ{" "}
          <span className="sm:hidden">
            <br />
          </span>
          ประจำปีการศึกษา 2567
          <br />
          โรงเรียนสตรีศรีน่าน อำเภอเมืองน่าน จังหวัดน่าน
        </div>

        <div className="w-full bg-[#50cc7c] text-center p-2 text-white">
          ข้อมูลผู้ทดสอบ
        </div>

        {examResult.status == "loading" ? (
          <div className="flex flex-col gap-y-2">
            <div className="flex w-72 h-4 bg-[#50cc7c] rounded-md animate-pulse"></div>
            <div className="flex w-72 h-4 bg-[#50cc7c] rounded-md animate-pulse"></div>
            <div className="flex w-72 h-4 bg-[#50cc7c] rounded-md animate-pulse"></div>
            <div className="flex w-72 h-4 bg-[#50cc7c] rounded-md animate-pulse"></div>
            <div className="flex w-72 h-4 bg-[#50cc7c] rounded-md animate-pulse"></div>
          </div>
        ) : (
          <div className="flex flex-col gap-y-2">
            <p>
              ชื่อผู้สอบ :{" "}
              {examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                "student_prefix"
              ] +
                " " +
                examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                  "student_firstname"
                ] +
                " " +
                examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                  "student_surname"
                ]}
            </p>
            <p>
              เลขประจำตัวผู้สอบ :{" "}
              {examResult?.data?.["data"]?.["data"]?.["exam_id"]}
            </p>
            <p className="flex w-full justify-between max-w-sm">
              เลขบัตรประจำตัวประชาชน :{" "}
              {!eye
                ? examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                    "student_identityid"
                  ].replace(
                    /\d{1}\d{4}\d{3}(\d{2})(\d{2})(\d{1})/,
                    "X-XXXX-XXX$1-$2-$3"
                  )
                : examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                    "student_identityid"
                  ].replace(
                    /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
                    "$1-$2-$3-$4-$5"
                  )}
              {eye ? (
                <Eye
                  className="w-5"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
              ) : (
                <EyeOff
                  className="w-5"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
              )}
            </p>
            <p>
              ระดับชั้นประถมศึกษาปีที่ :{" "}
              {examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                "student_level"
              ].match(/\.*(\d)/g)}
            </p>
            <p>
              โรงเรียน :{" "}
              {
                examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
                  "student_school"
                ]
              }
            </p>
          </div>
        )}

        <div className="w-full bg-[#50cc7c] text-center p-2 text-white">
          รายละเอียดผลการทดสอบ
        </div>

        <Button
          onClick={() => handleOnClick("score")}
          className="flex sm:w-3/4 place-self-center gap-2 bg-primary hover:bg-opacity-20 active:bg-primary/90"
        >
          <FileText />
          ผลการทดสอบแยกรายวิชา
        </Button>

        <Button
          onClick={() => handleOnClick("certificate")}
          className={`flex sm:w-3/4 place-self-center gap-2 bg-sky-400 hover:bg-sky-300/90 active:bg-sky-300/80 ${isCertAvailable?.data?.data.certificate == true ? "": "hidden"}`}
        >
          <FileBadge />
          เกียรติบัตร
        </Button>

        <Table className="text-center text-black font-bold">
          <TableHeader className="border border-primary [&_tr]:border-b-primary bg-[#50cc7c]">
            <TableRow>
              <TableHead className="font-bold text-center text-white">
                วิชา
              </TableHead>
              <TableHead className="font-bold text-center text-white">
                คะแนนที่ได้/คะแนนเต็ม
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="bg-white">
            {scores.map((scoreData) => (
              <TableRow
                key={scoreData.subject}
                className="border border-primary"
              >
                <TableCell className=" text-center">
                  {scoreData.subject}
                </TableCell>
                <TableCell className="text-center text-primary bg-[#50cc7c] bg-opacity-30">
                  <span className="text-2xl">
                    {examResult.status === "loading" ? (
                      <span className="animate-pulse text-opacity-30">
                        {" "}
                        ...{" "}
                      </span>
                    ) : examResult?.data?.data?.["data"]?.["score"][0]?.[
                        "miss_exam"
                      ] === 1 ? (
                      <span className="text-red-400">-</span>
                    ) : (
                      scoreData.score
                    )}
                  </span>
                  <sup className="font-normal">/{scoreData.max}</sup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter className="text-black bg-white font-bold">
            <TableRow className="border border-primary">
              <TableCell className="text-center">รวม</TableCell>
              <TableCell className="text-center text-primary bg-[#50cc7c] bg-opacity-30">
                <span className="text-2xl font-bold">
                  {examResult.status === "loading" ? (
                    <span className="animate-pulse text-opacity-30"> ... </span>
                  ) : examResult?.data?.data?.["data"]?.["score"][0]?.[
                      "miss_exam"
                    ] === 1 ? (
                    <span className="text-red-400">-</span>
                  ) : (
                    scores.reduce((previous, current) => {
                      return previous + (current.score ? current.score : 0);
                    }, 0)
                  )}
                </span>
                <sup className="font-normal">
                  /
                  {scores.reduce((previous, current) => {
                    return previous + current.max;
                  }, 0)}
                </sup>
              </TableCell>
            </TableRow>
            <TableRow className="border border-primary">
              <TableCell>ลำดับที่ของระดับชั้น</TableCell>
              <TableCell className="text-primary font-bold">
                {examResult.status === "loading" ? (
                  <span className="animate-pulse text-opacity-30"> ... </span>
                ) : examResult?.data?.data?.["data"]?.["score"][0]?.[
                    "miss_exam"
                  ] === 1 ? (
                  <span className="text-red-400">ขาดสอบ</span>
                ) : (
                  examResult?.data?.["data"]?.["data"]?.["score"][0]?.[
                    "student_rank"
                  ]
                )}
              </TableCell>
            </TableRow>
            <TableRow className="border border-primary">
              <TableCell>จำนวนผู้เข้าสอบในระดับชั้น</TableCell>
              <TableCell className="text-primary font-bold">
                {examResult.status === "loading" ? (
                  <span className="animate-pulse text-opacity-30"> ... </span>
                ) : (
                  examResult?.data?.["data"]?.["level_amount"]?.["_count"][
                    "student_level"
                  ]
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>

        {examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
          "student_level"
        ] == "level6" ? (
          <div className="w-full bg-[#50cc7c] text-center p-2 text-white">
            สิทธิ์ในการเข้าเรียน
          </div>
        ) : null}

        {examResult?.data?.data?.["data"]?.["score"][0]?.["miss_exam"] === 1 ? (
          <span className="text-red-400">ขาดสอบ</span>
        ) : examResult?.data?.["data"]?.["data"]?.["students_user"]?.[
            "student_level"
          ] == "level6" ? (
          <Table className="text-center text-black font-bold">
            <TableHeader className="border border-primary [&_tr]:border-b-primary bg-[#50cc7c]">
              <TableRow>
                <TableHead className="font-bold text-center text-white">
                  ลำดับบัญชี
                </TableHead>
                <TableHead className="font-bold text-center text-white">
                  ลำดับที่ได้
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white">
              {examResult.status === "loading" ? (
                <span className="animate-pulse text-opacity-30"> ... </span>
              ) : (
                accountRanking.map((account) => (
                  <TableRow key={account.id} className="border border-primary">
                    <TableCell className="text-center">{account.id}</TableCell>
                    <TableCell className="text-center font-normal">
                      {account?.rank}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        ) : null}

        {/* Note */}
        {footerNote.isLoading ? (
          <div className="flex justify-center items-center">
            <Loader
              size={64}
              className="motion-safe:animate-spin text-blue-300 mt-20"
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <span className="font-bold underline">หมายเหตุ</span>
            <div
              dangerouslySetInnerHTML={{
                __html: String(footerNote?.data?.data.notescore?.[0].content),
              }}
            />
          </div>
        )}

        <Link to={"/profile"} className="place-self-center">
          <Button className="bg-primary hover:bg-opacity-20 active:bg-primary/90">
            กลับหน้าหลัก
          </Button>
        </Link>
      </div>
    </ProtectedRoute>
  );
}
