/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useMemo } from "react";
import Dropdown from "@/components/ui/dropdown";
import { Input } from "@/components/ui/input";
import Header from "@/components/ui/header";
import { Button } from "@/components/ui/button";
import { getSubdistrict, getProvince } from "@/lib/api/getAddress";
import Swal from "sweetalert2";
import { ProtectedRoute } from "@/components/ProtectedRoute";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "@/hooks/AuthContext";
import { updateUser } from "@/lib/api/updateUser";
import { useQueries } from "@tanstack/react-query";
import { getRangeEditProfile } from "@/lib/api/getTimeSwitch";
import Autocomplete from "react-widgets/Combobox";

interface Student {
  prefix?: string;
  firstName?: string;
  lastName?: string;
  nationalID?: string;
  DOB?: string;
  phone?: string;
  level?: string;
  // address: string,
  house_no?: string;
  village_no?: string;
  addr_alley?: string;
  addr_lane?: string;
  addr_street?: string;
  province?: string;
  district?: string;
  subDistrict?: string;
  zipCode?: string;
  schoolID?: string;
  schoolProvince?: string;
  schoolDistrict?: string;
  schoolSubDistrict?: string;
}

// interface SubDistrict {
//   id: string;
//   zip_code: string;
//   name_th: string;
//   name_en: string;
//   districts: {
//     name_th: string;
//     name_en: string;
//     provinces: {
//       name_th: string;
//       name_en: string;
//     };
//   };
// }

interface Parent {
  prefix?: string;
  firstName?: string;
  lastName?: string;
  relation?: string;
  phone?: string;
}

interface Teacher {
  prefix?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

interface Status {
  isNationalID: boolean;
  isVisible: boolean;
  isAcceptCondition: boolean;
}

// interface Sds {
//   zip_code: number;
//   name_th: string;
//   name_en: string;
//   id: number;
// }

// interface Scds {
//   zip_code: number;
//   name_th: string;
//   name_en: string;
//   id: number;
// }

const EditProfile = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [canEdit] = useQueries({
    queries: [
      {
        queryKey: ["editProfile"],
        queryFn: getRangeEditProfile,
      },
    ],
  });

  useMemo(() => {
    // console.log(canEdit);
    if (canEdit.data?.["data"]?.["config"] === false) {
      Swal.fire({
        title: "ไม่สามารถแก้ไขข้อมูลได้",
        text: "ระบบไม่อยู่ในช่วงเปิดรับสมัคร",
        icon: "error",
        confirmButtonText: "ตกลง",
      }).then(() => {
        navigate("/profile");
      });
    }
  }, [canEdit.data?.["data"]?.["config"]]);

  const [dataStudent, setDataStudent] = useState<Student>({
    prefix: "",
    firstName: "",
    lastName: "",
    nationalID: auth?.user?.data?.student_identityid || "",
    DOB: "",
    phone: "",
    level: "",
    house_no: "",
    village_no: "",
    addr_alley: "",
    addr_lane: "",
    addr_street: "",
    province: "",
    district: "",
    subDistrict: "",
    zipCode: "",
    schoolID: "",
    schoolProvince: "",
    schoolDistrict: "",
    schoolSubDistrict: "",
  });

  const [dataParents, setDataParents] = useState<Parent[]>([
    {
      prefix: "123",
      firstName: "123",
      lastName: "123",
      relation: "123",
      phone: "123",
    },
    {
      prefix: "123",
      firstName: "123",
      lastName: "123",
      relation: "123",
      phone: "123",
    },
  ]);

  const [dataTeacher, setDataTeacher] = useState<Teacher>({
    prefix: "123",
    firstName: "123",
    lastName: "123",
    phone: "123",
  });

  const [status, setStatus] = useState<Status>({
    isNationalID: false,
    isVisible: true,
    isAcceptCondition: false,
  });

  const prefixs = [
    { key: "เด็กชาย", value: "เด็กชาย" },
    { key: "เด็กหญิง", value: "เด็กหญิง" },
  ];
  const prefixsAdult = [
    { key: "นาย", value: "นาย" },
    { key: "นาง", value: "นาง" },
    { key: "นางสาว", value: "นางสาว" },
  ];

  const user = auth?.user?.data;

  // const [studentAddress, schoolAddress, provinces, schoolProvinces] =
  const [studentAddress, schoolAddress, schoolProvinces] =
    useQueries({
      queries: [
        {
          queryKey: ["studentAddress", user?.student_addr_subdistrict],
          queryFn: () => getProvince(user?.student_addr_subdistrict || 0),
          enabled: user !== undefined,
        },
        {
          queryKey: ["schoolAddress", user?.student_school_subdistrict],
          queryFn: () => getProvince(user?.student_school_subdistrict || 0),
          enabled: user !== undefined,
        },
        // {
        //   queryKey: ["provinces", dataStudent.province],
        //   queryFn: () => getSubdistrict(dataStudent.province),
        //   enabled: dataStudent.province !== "",
        // },
        {
          queryKey: ["schoolProvinces", dataStudent.schoolProvince],
          queryFn: () => getSubdistrict(dataStudent.schoolProvince),
          enabled: dataStudent.schoolProvince !== "",
        },
      ],
    });

  useMemo(() => {
    setDataStudent({
      ...dataStudent,
      ["prefix"]: user?.student_prefix,
      ["firstName"]: user?.student_firstname,
      ["lastName"]: user?.student_surname,
      ["nationalID"]: user?.student_identityid?.replace(
        /(\d)(\d{4})(\d{5})(\d{2})(\d)/,
        "$1-$2-$3-$4-$5"
      ),
      ["DOB"]: user?.student_birthday?.replace(/[/](\d{2})$/, "/25$1"),
      ["phone"]: user?.student_mobile,
      ["level"]: user?.student_level,
      ["house_no"]: user?.student_house_no,
      ["village_no"]: user?.student_village_no,
      ["addr_alley"]: user?.student_addr_alley,
      ["addr_lane"]: user?.student_addr_lane,
      ["addr_street"]: user?.student_addr_street,
      ["province"]: "",
      ["district"]: "",
      ["subDistrict"]: user?.student_addr_subdistrict + "",
      ["zipCode"]: "",
      ["schoolID"]: user?.student_school,
      ["schoolProvince"]: "",
      ["schoolDistrict"]: "",
      ["schoolSubDistrict"]: user?.student_school_subdistrict + "",
    });
    setDataParents([
      {
        ...dataParents[0],
        ["prefix"]: user?.parent1_prefix,
        ["firstName"]: user?.parent1_firstname,
        ["lastName"]: user?.parent1_surname,
        ["relation"]: user?.parent1_relationship,
        ["phone"]: user?.parent1_mobile,
      },
      {
        ...dataParents[1],
        ["prefix"]: user?.parent2_prefix,
        ["firstName"]: user?.parent2_firstname,
        ["lastName"]: user?.parent2_surname,
        ["relation"]: user?.parent2_relationship,
        ["phone"]: user?.parent2_mobile,
      },
    ]);
    setDataTeacher({
      ...dataTeacher,
      ["prefix"]: user?.teacher_prefix,
      ["firstName"]: user?.teacher_firstname,
      ["lastName"]: user?.teacher_surname,
      ["phone"]: user?.teacher_mobile,
    });
  }, [user]);

  useEffect(() => {
    setDataStudent({
      ...dataStudent,
      ["province"]:
        studentAddress?.data?.data?.data?.[0]?.districts.provinces.name_th,
      ["district"]: studentAddress?.data?.data?.data?.[0]?.districts.name_th,
      ["zipCode"]: studentAddress?.data?.data?.data?.[0]?.zip_code,
    });
  }, [studentAddress.isInitialLoading]);

  useEffect(() => {
    setDataStudent({
      ...dataStudent,
      ["schoolProvince"]:
        schoolAddress?.data?.data?.data?.[0]?.districts.provinces.name_th,
      ["schoolDistrict"]:
        schoolAddress?.data?.data?.data?.[0]?.districts.name_th,
    });
  }, [schoolAddress.isInitialLoading]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    Swal.fire({
      title: "Confirm?",
      text: "โปรดตรวจสอบข้อมูลให้เรียบร้อยก่อนการแก้ไขข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        updateUser({
          student_identityid_type: null,
          student_identityid: user?.student_identityid || "",
          student_prefix: dataStudent.prefix || "",
          student_firstname: dataStudent.firstName || "",
          student_surname: dataStudent.lastName || "",
          student_birthday: user?.student_birthday || "",
          student_level: dataStudent.level || "",
          student_school: dataStudent.schoolID || "",
          student_school_subdistrict: dataStudent.schoolSubDistrict || "",
          student_house_no: dataStudent.house_no,
          student_village_no: dataStudent.village_no,
          student_addr_alley: dataStudent.addr_alley,
          student_addr_lane: dataStudent.addr_lane,
          student_addr_street: dataStudent.addr_street,
          student_addr_subdistrict: dataStudent.subDistrict || "",
          student_mobile: dataStudent.phone || "",
          parent1_prefix: dataParents[0].prefix || "",
          parent1_firstname: dataParents[0].firstName || "",
          parent1_surname: dataParents[0].lastName || "",
          parent1_relationship: dataParents[0].relation || "",
          parent1_mobile: dataParents[0].phone || "",
          parent2_prefix: dataParents[1].prefix || "",
          parent2_firstname: dataParents[1].firstName || "",
          parent2_surname: dataParents[1].lastName || "",
          parent2_relationship: dataParents[1].relation || "",
          parent2_mobile: dataParents[1].phone || "",
          teacher_prefix: dataTeacher.prefix || "",
          teacher_firstname: dataTeacher.firstName || "",
          teacher_surname: dataTeacher.lastName || "",
          teacher_mobile: dataTeacher.phone || "",
        })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire("Successful", "แก้ไขข้อมูลสำเร็จ", "success").then(
                () => {
                  auth?.signin(String(auth?.user?.data?.student_identityid), auth?.user?.data?.student_birthday);
                  navigate("/Profile");
                }
              );
            } else {
              Swal.fire("Error", "เกิดข้อผิดพลาดบางอย่าง", "error");
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-cond-assign, no-constant-condition
            if ((err.response.data.message = "Empty data")) {
              Swal.fire("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
            }
          });
      }
    });
  };

  // useEffect(() => {
  //   console.log(dataStudent);
  // }, [dataStudent, dataParents, dataTeacher]);

  return (
    <ProtectedRoute>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="flex flex-col gap-y-8"
      >
        <div className="flex flex-col gap-y-2">
          <Header text={"ข้อมูลนักเรียน"} />
          <div className="grid grid-cols-1 gap-y-4 gap-x-8">
            <div>
              <label htmlFor="studentFirstName" className="font-bold mb-2">
                ชื่อจริง
              </label>
              <div className="grid grid-cols-4 gap-x-2">
                <Dropdown
                  name="studentPrefix"
                  id="studentPrefix"
                  isRequire={false}
                  placeHolder={dataStudent.prefix || "คำนำหน้า"}
                  onChange={(value: string) =>
                    setDataStudent({ ...dataStudent, ["prefix"]: value })
                  }
                  obj={prefixs}
                  style="col-span-1"
                />
                <Input
                  name="studentFirstName"
                  id="studentFirstName"
                  required={true}
                  className="border-2 border-black col-span-3"
                  onChange={(e) =>
                    setDataStudent({
                      ...dataStudent,
                      ["firstName"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="กรอกชื่อจริง"
                  defaultValue={dataStudent.firstName}
                />
              </div>
            </div>
            <div>
              <label htmlFor="studentLastName" className="font-bold mb-2">
                นามสกุล
              </label>
              <Input
                name="studentLastName"
                id="studentLastName"
                required={true}
                className="border-2 border-black"
                onChange={(e) =>
                  setDataStudent({
                    ...dataStudent,
                    ["lastName"]: e.target.value,
                  })
                }
                type="text"
                placeholder="กรอกนามสกุล"
                defaultValue={dataStudent.lastName}
              />
            </div>
            <div>
              <label htmlFor="studentNationalID" className="font-bold mb-2">
                เลขบัตรประชาชน
              </label>
              <Input
                name="studentNationalID"
                id="studentNationalID"
                className="border-2 border-black bg-gray cursor-not-allowed"
                readOnly={true}
                value={dataStudent.nationalID}
                type="text"
              />
            </div>
            <div>
              <label htmlFor="studentDOB" className="font-bold mb-2">
                วัน เดือน ปีเกิด
              </label>
              <Input
                name={"datePicker"}
                className="border-2 border-black bg-gray cursor-not-allowed"
                readOnly={true}
                id={"studentDOB"}
                value={dataStudent.DOB}
              />
            </div>
            <div>
              <label htmlFor="studentPhone" className="font-bold mb-2">
                หมายเลขโทรศัพท์ของนักเรียน
              </label>
              <Input
                id="studentPhone"
                onChange={(e) =>
                  setDataStudent({
                    ...dataStudent,
                    ["phone"]: e.target.value,
                  })
                }
                className="border-2 border-black"
                placeholder="กรอกหมายเลขโทรศัพท์"
                maxLength={10}
                minLength={10}
                pattern="^\d{10}$"
                required={false}
                value={dataStudent.phone}
              />
            </div>
            <div>
              <label htmlFor="studentLevel" className="font-bold mb-2">
                ระดับชั้นการศึกษา
              </label>
              <Input
                name="studentLevel"
                id="studentLevel"
                required={true}
                className="border-2 border-black bg-gray cursor-not-allowed"
                type="text"
                placeholder="เลือกระดับชั้นการศึกษา"
                value={
                  "ประถมศึกษาปีที่ " + dataStudent.level?.match(/\d+/g)?.[0]
                }
                readOnly={true}
              />
            </div>
            {/* <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                ปัจจุบันอาศัยอยู่ บ้านเลขที่{" "}
              </label>
              <Input
                name="student_house_no"
                id="student_house_no"
                required={true}
                className="border-2 border-black"
                onChange={(e) =>
                  setDataStudent({
                    ...dataStudent,
                    ["house_no"]: e.target.value,
                  })
                }
                type="text"
                placeholder="บ้านเลขที่"
                value={dataStudent.house_no}
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                {"หมู่ที่ "}
              </label>
              <Input
                name="student_village_no"
                id="student_village_no"
                required={true}
                className="border-2 border-black"
                onChange={(e) =>
                  setDataStudent({
                    ...dataStudent,
                    ["village_no"]: e.target.value,
                  })
                }
                type="text"
                placeholder="หมู่ที่"
                value={dataStudent.village_no}
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                {"ถนน "}
              </label>
              <Input
                name="student_addr_street"
                id="student_addr_street"
                required={true}
                className="border-2 border-black"
                onChange={(e) =>
                  setDataStudent({
                    ...dataStudent,
                    ["addr_street"]: e.target.value,
                  })
                }
                type="text"
                placeholder="ถนน"
                value={dataStudent.addr_street}
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                {"จังหวัด "}
              </label>

              {studentAddress.isLoading ? (
                <div className="flex items-center p-4 h-10 w-full animate-pulse bg-primary/60 border-2 border-black/50 rounded-md text-white">
                  loading ...
                </div>
              ) : (
                <Autocomplete
                  containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                  data={provinces?.data?.data?.data?.map(
                    (pv: { name_th: string }) => {
                      return pv.name_th;
                    }
                  )}
                  defaultValue={
                    studentAddress?.data?.data?.data?.[0]?.districts.provinces
                      .name_th
                  }
                  onChange={(e: string) => {
                    setDataStudent({
                      ...dataStudent,
                      ["province"]: e,
                    });
                  }}
                  filter="contains"
                  placeholder="เลือกจังหวัด"
                />
              )}
            </div>
            <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                {"อำเภอ/เขต "}
              </label>
              {studentAddress.isLoading ? (
                <div className="flex items-center p-4 h-10 w-full animate-pulse bg-primary/60 border-2 border-black/50 rounded-md text-white">
                  loading ...
                </div>
              ) : (
                <Autocomplete
                  containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                  data={provinces?.data?.data?.data?.[0]?.districts.map(
                    (dt: { name_th: string }) => {
                      return dt.name_th;
                    }
                  )}
                  defaultValue={
                    studentAddress?.data?.data?.data?.[0]?.districts.name_th
                  }
                  onChange={(e: string) => {
                    setDataStudent({
                      ...dataStudent,
                      ["district"]: e,
                    });
                  }}
                  filter="contains"
                  placeholder="เลือกอำเภอ/เขต"
                />
              )}
            </div>
            <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                {"ตำบล/แขวง "}
              </label>
              {studentAddress.isLoading ? (
                <div className="flex items-center p-4 h-10 w-full animate-pulse bg-primary/60 border-2 border-black/50 rounded-md text-white">
                  loading ...
                </div>
              ) : (
                <Autocomplete
                  containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                  data={
                    provinces?.data?.data?.data?.[0]?.districts.find(
                      (dt: { name_th: string }) => {
                        return dt.name_th === dataStudent.district;
                      }
                    )?.subdistricts
                  }
                  defaultValue={studentAddress?.data?.data?.data?.[0]?.name_th}
                  onChange={(e) => {
                    setDataStudent({
                      ...dataStudent,
                      ["subDistrict"]: e.id + "",
                      ["zipCode"]: e.zip_code + "",
                    });
                  }}
                  filter="contains"
                  placeholder="เลือกตำบล/แขวง"
                  dataKey={"id"}
                  textField={"name_th"}
                />
              )}
            </div>
            <div className="md:col-span-1">
              <label htmlFor="studentAddress" className="font-bold mb-2">
                {"รหัสไปรษณีย์ "}
              </label>
              <Input
                className="border-2 border-black bg-gray cursor-not-allowed"
                name="student_addr_zipcode"
                id="student_addr_zipcode"
                required={false}
                type="text"
                placeholder="รหัสไปรษณีย์"
                readOnly={true}
                value={dataStudent.zipCode || ""}
              />
            </div> */}
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <Header text={"ข้อมูลโรงเรียน"} />
          <div className="md:col-span-2">
            <label htmlFor="studentSchoolID" className="font-bold mb-2">
              ปัจจุบันศึกษาอยู่โรงเรียน
            </label>
            <Input
              name="studentSchoolID"
              id="studentSchoolID"
              required={true}
              className="border-2 border-black"
              placeholder="เลือกชื่อโรงเรียน"
              value={dataStudent.schoolID}
              onChange={(e) =>
                setDataStudent({
                  ...dataStudent,
                  ["schoolID"]: e.target.value,
                })
              }
              type="text"
            />
          </div>
          <div>
            <label htmlFor="studentSchoolProvince" className="font-bold mb-2">
              จังหวัด
            </label>
            {schoolAddress.isLoading ? (
              <div className="flex items-center p-4 h-10 w-full animate-pulse bg-primary/60 border-2 border-black/50 rounded-md text-white">
                loading ...
              </div>
            ) : (
              <Autocomplete
                containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                data={schoolProvinces?.data?.data?.data?.map(
                  (pv: { name_th: string }) => {
                    return pv.name_th;
                  }
                )}
                defaultValue={
                  schoolAddress?.data?.data?.data?.[0]?.districts.provinces
                    .name_th
                }
                onChange={(e: string) => {
                  setDataStudent({
                    ...dataStudent,
                    ["schoolProvince"]: e,
                  });
                }}
                filter="contains"
                placeholder="เลือกจังหวัด"
              />
            )}
          </div>
          <div>
            <label htmlFor="studentSchoolDistrict" className="font-bold mb-2">
              อำเภอ/เขต
            </label>
            {schoolAddress.isLoading ? (
              <div className="flex items-center p-4 h-10 w-full animate-pulse bg-primary/60 border-2 border-black/50 rounded-md text-white">
                loading ...
              </div>
            ) : (
              <Autocomplete
                containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                data={schoolProvinces?.data?.data?.data?.[0]?.districts.map(
                  (dt: { name_th: string }) => {
                    return dt.name_th;
                  }
                )}
                defaultValue={
                  schoolAddress?.data?.data?.data?.[0]?.districts.name_th
                }
                onChange={(e: string) => {
                  setDataStudent({
                    ...dataStudent,
                    ["schoolDistrict"]: e,
                  });
                }}
                filter="contains"
                placeholder="เลือกอำเภอ/เขต"
              />
            )}
          </div>
          <div className="md:col-span-1">
            <label htmlFor="studentAddress" className="font-bold mb-2">
              ตำบล/แขวง
            </label>
            {schoolAddress.isLoading ? (
              <div className="flex items-center p-4 h-10 w-full animate-pulse bg-primary/60 border-2 border-black/50 rounded-md text-white">
                loading ...
              </div>
            ) : (
              <Autocomplete
                containerClassName="border-2 border-black rounded-md !ring-transparent !focus:ring-transparent !focus:drop-shadow-none"
                data={
                  schoolProvinces?.data?.data?.data?.[0]?.districts.find(
                    (dt: { name_th: string }) => {
                      return dt.name_th === dataStudent.schoolDistrict;
                    }
                  )?.subdistricts
                }
                defaultValue={schoolAddress?.data?.data?.data?.[0]?.name_th}
                onChange={(e) => {
                  setDataStudent({
                    ...dataStudent,
                    ["schoolSubDistrict"]: e.id + "",
                  });
                }}
                filter="contains"
                placeholder="เลือกตำบล/แขวง"
                dataKey={"id"}
                textField={"name_th"}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-y-2">
          <Header text={"ส่วนของผู้ปกครอง"} />
          <div className="font-bold my-4">ผู้ปกครองคนที่ 1</div>
          <div className="grid grid-cols-1 gap-y-4 gap-x-8">
            <div>
              <label htmlFor="parent1FirstName" className="font-bold mb-2">
                ชื่อจริง
              </label>
              <div className="grid grid-cols-4 gap-x-2">
                <Dropdown
                  name="parent1Prefix"
                  id="parent1Prefix"
                  placeHolder={dataParents[0].prefix || "คำนำหน้า"}
                  isRequire={false}
                  onChange={(value: string) =>
                    setDataParents([
                      { ...dataParents[0], ["prefix"]: value },
                      dataParents[1],
                    ])
                  }
                  obj={prefixsAdult}
                  style="col-span-1"
                />
                <Input
                  name="parent1FirstName"
                  id="parent1FirstName"
                  className="border-2 border-black col-span-3"
                  onChange={(e) =>
                    setDataParents([
                      { ...dataParents[0], ["firstName"]: e.target.value },
                      dataParents[1],
                    ])
                  }
                  required={true}
                  type="text"
                  placeholder="กรอกชื่อจริง"
                  value={dataParents[0].firstName}
                />
              </div>
            </div>
            <div>
              <label htmlFor="parent1LastName" className="font-bold mb-2">
                นามสกุล
              </label>
              <Input
                name="parent1LastName"
                id="parent1LastName"
                required={true}
                className="border-2 border-black"
                onChange={(e) =>
                  setDataParents([
                    { ...dataParents[0], ["lastName"]: e.target.value },
                    dataParents[1],
                  ])
                }
                type="text"
                placeholder="กรอกนามสกุล"
                value={dataParents[0].lastName}
              />
            </div>
            <div>
              <label htmlFor="parent1Relation" className="font-bold mb-2">
                ความสัมพันธ์
              </label>
              <Input
                name="parent1Relation"
                id="parent1Relation"
                required={true}
                className="border-2 border-black bg-gray cursor-not-allowed"
                onChange={(e) =>
                  setDataParents([
                    { ...dataParents[0], ["relation"]: e.target.value },
                    dataParents[1],
                  ])
                }
                type="text"
                placeholder="กรอกความสัมพันธ์"
                value={dataParents[0].relation}
                readOnly={true}
              />
            </div>
            <div>
              <label htmlFor="parent1Phone" className="font-bold mb-2">
                หมายเลขโทรศัพท์
              </label>
              <Input
                name="parent1Phone"
                id="parent1Phone"
                required={true}
                onChange={(e) =>
                  setDataParents([
                    { ...dataParents[0], ["phone"]: e.target.value },
                    dataParents[1],
                  ])
                }
                className="border-2 border-black"
                placeholder="กรอกหมายเลขโทรศัพท์"
                maxLength={10}
                minLength={10}
                pattern="^\d{10}$"
                value={dataParents[0].phone}
              />
            </div>
          </div>
          <div className="font-bold my-4">ผู้ปกครองคนที่ 2</div>
          <div className="grid grid-cols-1 gap-y-4 gap-x-8">
            <div>
              <label htmlFor="parent2FirstName" className="font-bold mb-2">
                ชื่อจริง
              </label>
              <div className="grid grid-cols-4 gap-x-2">
                <Dropdown
                  name="parent2Prefix"
                  id="parent2Prefix"
                  isRequire={false}
                  placeHolder={dataParents[1].prefix || "คำนำหน้า"}
                  onChange={(value: string) =>
                    setDataParents([
                      dataParents[0],
                      { ...dataParents[1], ["prefix"]: value },
                    ])
                  }
                  obj={prefixsAdult}
                  style="col-span-1"
                />
                <Input
                  name="parent2FirstName"
                  id="parent2FirstName"
                  required={false}
                  className="border-2 border-black col-span-3"
                  onChange={(e) =>
                    setDataParents([
                      dataParents[0],
                      { ...dataParents[1], ["firstName"]: e.target.value },
                    ])
                  }
                  type="text"
                  placeholder="กรอกชื่อจริง"
                  value={dataParents[1].firstName}
                />
              </div>
            </div>
            <div>
              <label htmlFor="parent2LastName" className="font-bold mb-2">
                นามสกุล
              </label>
              <Input
                required={false}
                name="parent2LastName"
                id="parent2LastName"
                className="border-2 border-black"
                onChange={(e) =>
                  setDataParents([
                    dataParents[0],
                    { ...dataParents[1], ["lastName"]: e.target.value },
                  ])
                }
                type="text"
                placeholder="กรอกนามสกุล"
                value={dataParents[1].lastName}
              />
            </div>
            <div>
              <label htmlFor="parent2Relation" className="font-bold mb-2">
                ความสัมพันธ์
              </label>
              <Input
                required={false}
                name="parent2Relation"
                id="parent2Relation"
                className="border-2 border-black bg-gray cursor-not-allowed"
                onChange={(e) =>
                  setDataParents([
                    dataParents[0],
                    { ...dataParents[1], ["relation"]: e.target.value },
                  ])
                }
                type="text"
                placeholder="กรอกความสัมพันธ์"
                value={dataParents[1].relation}
                readOnly={true}
              />
            </div>
            <div>
              <label htmlFor="parent2Phone" className="font-bold mb-2">
                หมายเลขโทรศัพท์
              </label>
              <Input
                required={false}
                name="parent2Phone"
                id="parent2Phone"
                onChange={(e) =>
                  setDataParents([
                    dataParents[0],
                    { ...dataParents[1], ["phone"]: e.target.value },
                  ])
                }
                className="border-2 border-black"
                placeholder="กรอกหมายเลขโทรศัพท์"
                maxLength={10}
                minLength={10}
                pattern="^\d{10}$"
                value={dataParents[1].phone}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <Header text={"ส่วนของอาจารย์"} />
          <div className="grid grid-cols-1 gap-y-4 gap-x-8">
            <div>
              <label htmlFor="teacherFirstName" className="font-bold mb-2">
                ชื่อจริง
              </label>
              <div className="grid grid-cols-4 gap-x-2">
                <Dropdown
                  name="teacherPrefix"
                  id="teacherPrefix"
                  isRequire={false}
                  placeHolder={dataTeacher.prefix || "คำนำหน้า"}
                  onChange={(value: string) =>
                    setDataTeacher({ ...dataTeacher, ["prefix"]: value })
                  }
                  obj={prefixsAdult}
                  style="col-span-1"
                />
                <Input
                  required={true}
                  name="teacherFirstName"
                  id="teacherFirstName"
                  className="border-2 border-black col-span-3"
                  onChange={(e) =>
                    setDataTeacher({
                      ...dataTeacher,
                      ["firstName"]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="กรอกชื่อจริง"
                  value={dataTeacher.firstName}
                />
              </div>
            </div>
            <div>
              <label htmlFor="teacherLastName" className="font-bold mb-2">
                นามสกุล
              </label>
              <Input
                required={true}
                name="teacherLastName"
                id="teacherLastName"
                className="border-2 border-black"
                onChange={(e) =>
                  setDataTeacher({
                    ...dataTeacher,
                    ["lastName"]: e.target.value,
                  })
                }
                type="text"
                placeholder="กรอกนามสกุล"
                value={dataTeacher.lastName}
              />
            </div>
            <div>
              <label htmlFor="teacherPhone" className="font-bold mb-2">
                หมายเลขโทรศัพท์
              </label>
              <Input
                required={true}
                name="teacherPhone"
                id="teacherPhone"
                onChange={(e) =>
                  setDataTeacher({
                    ...dataTeacher,
                    ["phone"]: e.target.value,
                  })
                }
                className="border-2 border-black"
                placeholder="กรอกหมายเลขโทรศัพท์"
                maxLength={10}
                minLength={10}
                pattern="^\d{10}$"
                value={dataTeacher.phone}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <input
            id="link-checkbox"
            type="checkbox"
            onChange={(e) =>
              setStatus({ ...status, ["isAcceptCondition"]: e.target.checked })
            }
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
          />
          <label
            htmlFor="link-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            ข้าพเจ้าขอยืนยันว่าข้อมูลทั้งหมดเหล่านี้เป็นจริง
            และอนุญาตให้นำข้อมูลไปใช้ต่อได้
          </label>
        </div>
        <div className="grid md:grid-cols-2 gap-x-8 gap-y-4">
          {/* <Link className="w-full" to="#"> */}
          <Button
            disabled={!status.isAcceptCondition}
            title="ปุ่มถัดไป"
            aria-label="ปุ่มถัดไป"
            className="w-full"
            // onClick={(e) =>{
            //   handleSubmit(e);
            // }}
            type="submit"
          >
            ถัดไป
          </Button>
          <Link to="/profile">
            <Button
              title="ปุ่มย้อนกลับ"
              aria-label="ปุ่มย้อนกลับ"
              className="w-full"
              variant={"gray"}
            >
              ย้อนกลับ
            </Button>
          </Link>
        </div>
      </form>
    </ProtectedRoute>
  );
};

export default EditProfile;
